import _arrayMap from "./_arrayMap";
import _copyArray from "./_copyArray";
import _isArray from "./isArray";
import _isSymbol from "./isSymbol";
import _stringToPath from "./_stringToPath";
import _toKey from "./_toKey";
import _toString from "./toString";
var exports = {};
var arrayMap = _arrayMap,
  copyArray = _copyArray,
  isArray = _isArray,
  isSymbol = _isSymbol,
  stringToPath = _stringToPath,
  toKey = _toKey,
  toString = _toString;

/**
 * Converts `value` to a property path array.
 *
 * @static
 * @memberOf _
 * @since 4.0.0
 * @category Util
 * @param {*} value The value to convert.
 * @returns {Array} Returns the new property path array.
 * @example
 *
 * _.toPath('a.b.c');
 * // => ['a', 'b', 'c']
 *
 * _.toPath('a[0].b.c');
 * // => ['a', '0', 'b', 'c']
 */
function toPath(value) {
  if (isArray(value)) {
    return arrayMap(value, toKey);
  }
  return isSymbol(value) ? [value] : copyArray(stringToPath(toString(value)));
}
exports = toPath;
export default exports;